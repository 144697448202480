import getConfig from 'next/config'

const plugins = require.context('./', true, /\.\/rc-\w+\.js$/)
const { publicRuntimeConfig } = getConfig()
const pluginsConfig = publicRuntimeConfig.plugins
export default async () => {
  const pluginList = {}
  plugins.keys()?.map((key) => {
    const KEY = key.match(/.\/(rc-\w+).(js|ts)$/)[1]
    const item = {
      [KEY]: plugins(key).default
    }
    return Object.assign(pluginList, item)
  })
  await Promise.all(pluginsConfig?.map(item => pluginList[item]()))
}
