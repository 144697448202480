import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useSelector, useDispatch } from 'react-redux'
import { Popover, Button } from 'antd'
import { useScrollPosition } from 'react-use-scroll-position'
import { useRouter } from 'next/router'
import CityChoose from './module/city'
import InoCity from './module/city_ino'
import { $cookie, $hooks, sessionstorage } from '$utils'
import { loginconfirm, logout, getphonesource } from './service'
import './index.scss'

const Header = () => {
  const router = useRouter()
  const usedispatch = useDispatch()
  const { user, base } = useSelector(store => store)// 登录信息
  const city = useSelector(store => store.city)
  const [fixed, setFixed] = useState(false)// fixedHeader
  const [inoVisible, setInoVisible] = useState()// fixedHeader
  const [isHovering, hoverProps] = $hooks.useHover({ mouseEnterDelayMS: 200, mouseLeaveDelayMS: 500 })
  // 滚动>40时显示固定导航栏
  const { x, y } = useScrollPosition()
  useEffect(() => {
    if (y > 40) {
      setFixed(true)
      if (document.getElementsByClassName('header-wrapper handFixed').length) {
        document.getElementsByClassName('header-wrapper handFixed')[0].style.left = `-${x}px`
      }
    } else {
      setFixed(false)
    }
  }, [x, y])

  // 退出登录
  const handleLogout = () => {
    global.$rbl.push('navbar-login_success_登出')
    logout({ token: $cookie.get('rrc_login_token') })
    usedispatch({ type: 'SET_USER_INFO', data: { phone: '', token: '' } })
    $cookie.remove('rrc_login_phone', '')
    $cookie.remove('rrc_login_token', '')
    $cookie.remove('rrc_userid', '')
    $cookie.remove('rrc_ucid', '')
    sessionstorage.del('intentPhone')// 退出登录后清空线索手机号
  }

  // 验证登录状态
  const loginConfirm = () => {
    const token = $cookie.get('rrc_login_token')
    const phone = $cookie.get('rrc_login_phone')

    if (phone && token) {
      loginconfirm({
        token
      }).then((res) => {
        if (res && res.status === 0) {
          usedispatch({
            type: 'SET_USER_INFO',
            data: res.data
          })
          // 存储365天小时60 * 60 * 24 * 365 封装的cookie 单位是s
          $cookie.set('rrc_login_phone', res.data.phone, { time: 31536000 })
          $cookie.set('rrc_login_token', res.data.token, { time: 31536000 })
        } else {
          handleLogout()
        }
      }).catch(() => {
        handleLogout()
      })
    }
  }

  // 获取400电话
  const getPhone = async () => {
    try {
      const isOffline = parseInt(city.ino || 0, 10)
      const params = { fr: city.fr, phone_city: '', other: '' }
      // 未开站城市非直接访问流量出全国的客服电话
      if (params.fr !== 'bd_other' && isOffline === 1) {
        params.phone_city = '全国'
        params.other = 0
      } else {
        params.phone_city = city.city_name
        params.other = isOffline
      }
      const res = await getphonesource({ ...params, platform: 'PC' })
      usedispatch({ type: 'SET_CITY_INFO', data: { phone_source: res.data } })
    } catch (error) {
      console.log(error)
    }
  }

  // 未开站城市弹窗
  // eslint-disable-next-line no-unused-vars
  const handleInoClick = () => {
    if (!city.ino) { return }
    setInoVisible(true)
    global.$rbl.push({
      event: 'pc_offline_city_show'
    })
  }
  // Popover 是动态追加的元素，未被采集到埋点，手动添加
  const handlePopoverGa = () => {
    global.$rbl.push({
      event: 'navbar-login_success_黄渤'
    })
  }


  const toLogin = () => {
    global.$rbl.push('navbar_login_click')
    router.push({
      pathname: '/login',
      query: {
        source: encodeURIComponent(window.location.href)
      }
    })
  }

  useEffect(() => {
    loginConfirm()
    getPhone()
  }, [])

  // 登录成功的 Popover
  const LoginPopover = () => {
    const popoverContent = (
      <div className="r-login-tooltip clearfix" onClick={handlePopoverGa}>
        <div className="r-login-tooltip__img" />
        <div className="r-login-tooltip__img-app">
          <span className="ad-title">关注微信立享好礼· 一键收藏 · 实时提醒</span>
        </div>
        <div className="r-login-tooltip__qrcode" />
        <p>已有20万人领取优惠</p>
        <Button className="r-login-tooltip__logout" onClick={handleLogout}>退出登录</Button>
      </div>
    )
    return <Popover overlayInnerStyle={{ padding: '0px' }} overlayClassName="login-pop-over-wraper" content={popoverContent} placement="bottom" title={null}> {`${user.phone.slice(0, 3)}···${user.phone.slice(7)}`}</Popover>
  }

  return (
    <>
      <div id="top" name="top" />
      {fixed && <div className="header-shadow" />}
      <div className={`header-wrapper clearfix ${(base.header_fixed && fixed) ? 'handFixed' : ''} ${base.header_light ? 'colorlight' : ''}`}>
        <div className="header ">
          <div className="header_logo" onClick={() => { window.location.href = 'https://www.renrenche.com' }} />
          <div className="header_nav">
            <div className="r-city" {...hoverProps}>
              <div className="r-city__choose" onClick={handleInoClick}>
                {city.city_name || '全国'}
              </div>
            </div>
            {/* 开站城市hover显示 */}
            {!city.ino && <CityChoose isShow={isHovering} />}
            {/* 未开站城市click显示 */}
            {Boolean(city.ino) && <InoCity modalVisible={inoVisible} onModalClose={() => { setInoVisible(false) }} />}

            <ul className="header_nav_menu">
              {/* <li data-click="navbar_buybutton_click"><a href="https://www.renrenche.com/ershouche">买车</a></li> */}
              <li data-click="navbar_buybutton_click"><Link href="/buy"><a>买车</a></Link></li>
              <li data-click="navbar_sellbutton_click" className="nav_sale"><Link href="/sell"><a>卖车</a></Link></li>
              {/* <li data-click="navbar_bbs_click"><a href="https://bbs.renrenche.com">论坛</a></li> */}
              {/* <li data-click="navbar_service_click"><Link href="/guarantee"><a>服务保障</a></Link></li> */}
              <li data-click="navbar_copartnerbutton_click"><Link href="/partners"><a>加盟合伙人</a></Link></li>
              {/* <li data-click="navbar_installment_click"><Link href="/installment/detail"><a>分期购车</a></Link></li> */}
            </ul>
            <span className="header_nav_tel">
              {
                !user.phone
                  ? <span suppressHydrationWarning onClick={toLogin}>登录</span>
                  : <LoginPopover />
              }
              {/* <span className="header_nav_tel_phone">{router.asPath.indexOf('sell') > -1 ? city.phone_source?.sale : city.phone_source?.buyer}</span> */}
              <span className="header_nav_tel_phone">{router.asPath.indexOf('sell') > -1 ? city.phone_source?.sale : ''}</span>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
