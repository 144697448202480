import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { animateScroll as scroll } from 'react-scroll'
import { useScrollPosition } from 'react-use-scroll-position'
import { Popover } from 'antd'
import style from './index.scss'
import { throttle } from '$utils'

import imgErweima from '~/img/share/erweima.jpg'
import imgErweimaApp from '~/img/share/erweima-app.png'

const SideShare = () => {
  const [showScrollTop, setShowScrollTop] = useState(false)
  const [posInfo, setPosInfo] = useState(400)

  const { y } = useScrollPosition()
  useEffect(() => {
    throttle(setPosInfo(y > 200 ? 200 : 400), 100)
    throttle(setShowScrollTop(y > 400), 100)
  }, [y])
  const erweimaPopover = (
    <img className={style['img-erweima']} src={imgErweima} alt="erweima" />
  )
  const erweimaAppPopover = (
    <Link href="/other/appdown">
      <a>
        <img
          className={style['img-erweima']}
          src={imgErweimaApp}
          alt="erweima"
        />
      </a>
    </Link>
  )
  return (
    <>
      <div className={style["r-side-share"]} style={{ top: `${posInfo}px` }}>
        <Link href="/sell">
          <a
            className={style["common-sale-fixed"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={style["common-fixed-img-sale"]}>
              <i className={`iconfont icon-jisumaiche ${style.shareicon} `} />
              <br />
              <span>极速</span>
              <br />
              <span>卖车</span>
            </div>
          </a>
        </Link>
        <Popover placement="left" content={erweimaAppPopover} trigger="click">
          <div className={style["common-share-app"]}>
            <i className={`iconfont icon-xiazai ${style.shareicon} `} />
            <br />
            <span>APP</span>
          </div>
        </Popover>
        <Popover placement="left" content={erweimaPopover} trigger="click">
          <div className={style["common-share-erweima"]}>
            <i className={`iconfont icon-erweima ${style.shareicon} `} />
            <br />
            <span>公众号</span>
          </div>
        </Popover>
        {showScrollTop && (
          <div
            className={style["common-share-top"]}
            onClick={scroll.scrollToTop}
          >
            <i className={`iconfont icon-down ${style.topicon} `} />
            <span>TOP</span>
          </div>
        )}
      </div>
    </>
  )
}

export default SideShare
