// import { useSelector } from "react-redux"
// import router from "next/router"
import React from "react"
import Link from "next/link"
import LazyLoad from "react-lazyload"
// import { $hooks } from "$utils"
import "./index.scss"

import imgWechat from "~/img/footer/wechat.jpg"
import imgDownAPP from "~/img/footer/down-app.png"

const Footer = () => (
    <>
      <LazyLoad offset={100} once height={400}>
        <div className="r-footer" data-exposure="bottominformation_display">
          <div className="r-footer-container">
            {/* 保障 */}
            {/* <div className="baozhang">
              <ul>
                <li>
                  <span>
                    <i className="iconfont icon-yinian" />
                    一年/两万公里质保
                  </span>
                </li>
                <li>
                  <span>
                    <i className="iconfont icon-ketui" />
                    90天可退
                  </span>
                </li>
                <li>
                  <span>
                    <i className="iconfont icon-a-249" />
                    249项检测
                  </span>
                </li>
                <li>
                  <span>
                    <i className="iconfont icon-jingxuan" />
                    精选优质车源
                  </span>
                </li>
                <li>
                  <span>
                    <i className="iconfont icon-yizhan" />
                    一站式服务
                  </span>
                </li>
              </ul> */}
            {/* </div> */}

            <div className="link clearfix">
              {/* 左侧链接 */}
              <div className="link-left">
                {/* 关于我们 */}
                <div className="link-left_item">
                  <div className="link-left_item-title">关于我们</div>
                  <ul>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "公司介绍" } }
                      })}
                    >
                      <a
                        href="https://sta.renrenche.com/legal/aboutus.html"
                        target="_blank"
                      >
                        公司介绍
                      </a>
                    </li>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "联系我们" } }
                      })}
                    >
                      <a
                        href="https://sta.renrenche.com/legal/contact.html"
                        target="_blank"
                      >
                        联系我们
                      </a>
                    </li>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "加入我们" } }
                      })}
                    >
                      <a
                        href="https://sta.renrenche.com/legal/career.html"
                        target="_blank"
                      >
                        加入我们
                      </a>
                    </li>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "加盟合伙人" } }
                      })}
                    >
                      <Link href="/partners">
                        <a> 加盟合伙人</a>
                      </Link>
                    </li>
                  </ul>
                  <ul>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "用户服务协议" } }
                      })}
                    >
                      <a
                        href="https://sta.renrenche.com/legal/terms.html"
                        target="_blank"
                      >
                        用户服务协议
                      </a>
                    </li>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "隐私政策" } }
                      })}
                    >
                      <a
                        href="https://sta.renrenche.com/legal/privacy.html"
                        target="_blank"
                      >
                        隐私政策
                      </a>
                    </li>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "法律声明" } }
                      })}
                    >
                      <a
                        href="https://sta.renrenche.com/legal/law.html"
                        target="_blank"
                      >
                        法律声明
                      </a>
                    </li>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "协议及声明" } }
                      })}
                    >
                      <Link href="/other/legal">
                        <a> 协议及声明</a>
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* 交易流程 */}
                <div className="link-left_item">
                  <div className="link-left_item-title">交易流程</div>
                  <ul>
                    {/* <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "买车流程" } }
                      })}
                    >
                      <Link href="/guarantee">
                        <a>买车流程</a>
                      </Link>
                    </li> */}
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "卖车流程" } }
                      })}
                    >
                      <a href="/sell" target="_blank" rel="noopener noreferrer">
                        卖车流程
                      </a>
                    </li>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "异地购车" } }
                      })}
                    >
                      <a
                        href="https://www.renrenche.com/p/10/renrenche_yidigouche"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        异地购车
                      </a>
                    </li>
                  </ul>
                </div>

                {/* 二手车精选 */}
                <div className="link-left_item">
                  <div className="link-left_item-title">二手车精选</div>
                  <ul>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "车型库" } }
                      })}
                    >
                      <a
                        href="https://car.renrenche.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        车型库
                      </a>
                    </li>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "二手车资讯" } }
                      })}
                    >
                      <a
                        href="https://news.renrenche.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        二手车资讯
                      </a>
                    </li>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "二手车问答" } }
                      })}
                    >
                      <a
                        href="https://ask.renrenche.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        二手车问答
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "二手车估价" } }
                      })}
                    >
                      <a
                        href="https://gujia.renrenche.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        二手车估价
                      </a>
                    </li>
                    <li
                      data-click={JSON.stringify({
                        event: "bottominformation-pagebottom_click",
                        content: { expand: { tag_value: "二手车迁入标准" } }
                      })}
                    >
                      <a
                        href="https://qian.renrenche.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        二手车迁入标准
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* 右侧 */}
              <div className="link-right clearfix">
                {/* <div className="link-right_tel">
                  <div className="number">
                    {router.asPath.indexOf('sell') > -1
                      ? city?.phone_source?.sale
                      : city?.phone_source?.buyer}
                  </div>
                  <div className="time">周一至周日 9:00-18:00</div>
                  <div className="text">免费咨询(咨询、建议、投诉)</div>
                </div> */}
                <div className="link-right_app">
                  <div className="item">
                    <span>关注微信</span>
                    <LazyLoad>
                      <img src={imgWechat} alt="wechat" />
                    </LazyLoad>
                  </div>
                  <div className="item">
                    <span>下载APP</span>
                    <LazyLoad>
                      <img src={imgDownAPP} alt="down-app" />
                    </LazyLoad>
                  </div>
                </div>
                {/* <div className="link-right_sns">
                  <div
                    className="item"
                    data-click={JSON.stringify({
                      event: "bottominformation-pagebottom_click",
                      content: { expand: { tag_value: "在线咨询" } }
                    })}
                  >
                    <a
                      href="https://wpa.qq.com/msgrd?v=3&uin=2912415885&site=qq&menu=yes"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="img">
                        <span className="zixun" />
                      </span>
                      在线咨询
                    </a>
                  </div>
                  <div
                    className="item"
                    data-click={JSON.stringify({
                      event: "bottominformation-pagebottom_click",
                      content: { expand: { tag_value: "官方微博" } }
                    })}
                  >
                    <a
                      href="https://weibo.com/renrenyouche"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="img">
                        <span className="weibo" />
                      </span>
                      官方微博
                    </a>
                  </div>
                  <div
                    className="item"
                    data-click={JSON.stringify({
                      event: "bottominformation-pagebottom_click",
                      content: { expand: { tag_value: "投诉建议" } }
                    })}
                  >
                    <a
                      href="https://form.mikecrm.com/f.php?t=twZnx6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="img">
                        <span className="tousu" />
                      </span>
                      投诉建议
                    </a>
                  </div>
                  <div
                    className="item"
                    {...hoverProps}
                    data-click={JSON.stringify({
                      event: "bottominformation-pagebottom_click",
                      content: { expand: { tag_value: "举报事故车" } }
                    })}
                  >
                    <span className="img">
                      <span className="jubao" />
                    </span>
                    举报事故车
                    {isHovering && <div className="popover-img" />}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="r-footer-copyright">
            <div className="r-footer-copyright-container">
              <a
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502035802"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="public" />
                京公网安备 11010502035802号
              </a>
              <a
                href="https://credit.szfw.org/CX20170712035072080313.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="chengxin" />
                诚信示范单位
              </a>
              <a
                href="https://sta.renrenche.com/legal/license.html"
                target="_blank"
              >
                <div className="yingyezhizhao" />
                营业执照
              </a>
              {/* <span>Copyright © 2021, renrenche.com, All Rights Reserved 京ICP证160608号  京ICP备2021013707号-1</span> */}
              <span>
                Copyright © 2021, renrenche.com, All Rights Reserved
                <a href="https://beian.miit.gov.cn/" target="_blank">
                  京ICP备2021013707号-1
                </a>
              </span>
            </div>
          </div>
        </div>
      </LazyLoad>
    </>
  )

export default Footer
