export default (ctx) => {
  let UA = ''
  if (!process.browser) {
    UA = ctx?.req?.headers['user-agent'] || ''
  } else {
    UA = window.navigator.userAgent
  }
  const regIE = UA.match(/MSIE (\d+\.\d+)/)
  const IEVersion = regIE ? Number(regIE[1]) : null
  if (IEVersion && IEVersion <= 10) {
    ctx.store.dispatch({
      type: 'SET_IE',
      data: {
        version: IEVersion,
        time: Date.now()
      }
    })
  }
  // console.log('IEVersion======', IEVersion)
  return IEVersion
}
