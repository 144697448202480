import { base64Encrypt, getPagename } from '../../../../utils/utils'
import {
  merge,
  logDataJob,
  logV,
  logU,
  logDt,
  logAc,
  logSid,
  logUuid,
  logUserid,
  logCity,
  logEp,
  logFr,
  epGetABTest,
  epGetSS,
  epCityName,
  epChannel,
  browserInfo, detailCarId
} from './init-config'

const defaultOption = {
  logUrl:
    process.env.NODE_ENV === 'production'
      ? '//log.renrenche.com'
      : '//log-test.renrenaiche.cn'
}
export default class Ga {
  constructor(options = defaultOption) {
    /**
     * @desc 埋点参数 初始化
     * @path 数据规范 http://wiki.renrenaiche.cn/pages/viewpage.action?pageId=8825235
     * @path 规范2 http://wiki.renrenaiche.cn/pages/viewpage.action?pageId=9988121
     * @docs ./init-config.js  每个参数的获取 
     */
    this.ga = {
      data_job: options.data_job || logDataJob(),
      logv: options.logv || logV,
      u: options.u || logU(window.location),
      dt: options.dt || logDt,
      ac: options.ac || logAc(),
      log_device: 'pc',
      source: 'pc', // 替代旧版的log_device字段
      device: 'pc',
      sid: options.sid || logSid(),
      uuid: options.uuid || logUuid(),
      userid: options.userid || logUserid(),
      city: options.city || logCity(),
      ep: options.ep || logEp,
      fr: options.fr || logFr()
    }
    Object.assign(this.ga, options)
    this.logUrl = options.logUrl || defaultOption.logUrl
    this.instance = null
  }


  /**
   *@desc 初始化日志信息 & 合并入参信息
   */
  push(options = {}) {
    if (typeof options === 'string') {
      options = {
        event: options
      }
    }

    if (!options.event) { return false }
    const json = {
      event: options.event,
      abtest: epGetABTest(),
      ss: epGetSS(),
      page_name: getPagename(),
      last_page_name: document.referrer,
      content: {
        page: window.location.href,
        fr: options.fr || logFr(),
        browserInfo: browserInfo(),
        type: options.type || '',
        city: options.city || this.ga.city || logCity(),
        cityName: options.cityName || epCityName(),
        channel: epChannel(),
        global_event_properties: detailCarId()
      }
    }

    // ga初始化时空数据 push时再次获取
    this.ga.uuid = options.uuid || logUuid()
    this.ga.userid = options.userid || logUserid()
    this.ga.fr = options.fr || logFr()
    // 合并json
    const ep = merge(json, options)

    // city 与 ep中的一致
    this.ga.city = ep.content.city
    // ts 时间戳
    this.ga.ts = Date.now()
    this.ga.ep = JSON.stringify(ep)

    this._toString()
    return true
  }

  trackPageTiming() {
    const win = window
    const performance = win.performance || win.mozPerformance || win.msPerformance || win.webkitPerformance

    if (!performance || !performance.timing) {
      return
    }

    // append data from window.performance
    // eslint-disable-next-line prefer-destructuring
    const timing = performance.timing
    const data = {
      // network
      redirect: timing.fetchStart - timing.navigationStart, // 跳转时间
      dns: timing.domainLookupEnd - timing.domainLookupStart, // DNS查找时间
      connect: timing.connectEnd - timing.connectStart, // 建连时间
      network: timing.connectEnd - timing.navigationStart, // 网络总耗时

      // backend
      send: timing.responseStart - timing.requestStart, // 后端时间
      receive: timing.responseEnd - timing.responseStart, // 接收时间
      backend: timing.responseEnd - timing.requestStart, // 接收时间

      // frontend
      render: timing.loadEventEnd - timing.loadEventStart, // loadevent持续
      dom: timing.domComplete - timing.domLoading, // load时间
      frontend: timing.loadEventEnd - timing.domLoading, // 前端时间

      // 全过程系列
      load: timing.loadEventEnd - timing.navigationStart, // 完全加载全过程
      domReady: timing.domContentLoadedEventStart - timing.navigationStart, // domready时间
      interactive: timing.domInteractive - timing.navigationStart, // 可操作时间
      ttfb: timing.responseStart - timing.navigationStart // Time to First Byte
    }

    // msFirstPaint is IE9+ http://msdn.microsoft.com/en-us/library/ff974719
    if (timing.msFirstPaint) {
      data.firstPaint = timing.msFirstPaint
    }

    // http://www.webpagetest.org/forums/showthread.php?tid=11782
    if (win.chrome && typeof win.chrome.loadTimes === 'function') {
      const loadTimes = win.chrome.loadTimes()
      data.firstPaint = Math.round((loadTimes.firstPaintTime - loadTimes.startLoadTime) * 1000)
    }

    const ep = {
      event: 'pagetiming',
      content: {
        page: win.location.href,
        timing: data,
        new_page_name: win.location.href
      },
      userAgent: navigator.userAgent
    }

    this.push(ep)
  }

  /**
   * 基础属性 转成连接需要的参数
   * @memberof
   */
  _toString() {
    let str = ''
    for (const attr in this.ga) {
      str += `${attr}=${encodeURIComponent(this.ga[attr])}&`
    }
    str = str.substring(0, str.length - 1)
    /**
     * @desc  url 含有 debugger=log log上报不加密
    */
    const url = `${this.logUrl}/v2?${str}`
    const str_encrypted = `log_version=${this.ga.logv}&is_encrypt=1&data_job=${this.ga.data_job}&content=${base64Encrypt(str)}`
    const url_encrypted = `${this.logUrl}/v2?${str_encrypted}`
    setTimeout(() => {
      if(window && window.location.href.includes('debugger=log')) {
        Ga.transfer(url)
        return -1
      }
      Ga.transfer(url_encrypted)
    }, 0)
  }

  static transfer(url, errorFunc) {
    let img = new Image()
    const num = Math.floor(2147483648 * Math.random()).toString(36)
    const d = `mini_rls_log_${num}`
    window[d] = img
    img.onload = img.onerror = img.onabort = () => {
      img.onload = img.onerror = img.onabort = null
      img = window[d] = null
      delete window[d]
      errorFunc && errorFunc(url)
    }
    img.src = url
  }
}
