import React, {
 useEffect, useState, useRef, useMemo, useReducer 
} from "react"
import PropTypes from "prop-types"
import { Modal } from "antd"
import "./index.scss"
import { useRouter } from "next/router"
import { useSelector, useDispatch } from "react-redux"
import { $cookie, localstorage } from "$utils"
import { getPublicVariables, getNavigationCityInfo } from "./service"

const InoCity = (props) => {
  const usedispatch = useDispatch()
  const router = useRouter()
  const { city, base } = useSelector(store => store)
  const [localcitychooseShow, setLocalcitychooseShow] = useState(false) // 城市定位
  const [citychooseShow, setCitychooseShow] = useState(false) // 异地购车
  const [chooseCities, setChooseCities] = useState([]) // 异地购车城市
  const questionList = useRef()
  const [lastOne, setLastOne] = useState(false)
  const [mostThree, setMostThree] = useState(false)
  const [localState, dispatch] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      cityArea: [],
      cityLetters: [],
      hot: [],
      near: []
    }
  )
  // 获取周边城市 type: 1 hot+near 2 cityLetters 3 cityArea
  const handleGetZhoubian = () => {
    const isCityLetters = localstorage.get("rrc_city_letters")?.length > 0
    const isCityArea = localstorage.get("rrc_city_area")?.length > 0
    if (isCityLetters && !localState.cityLetters?.length) {
      dispatch({
        cityLetters: localstorage.get("rrc_city_letters")
      })
    }
    if (isCityArea && !localState.cityArea?.length) {
      dispatch({
        cityArea: localstorage.get("rrc_city_area")
      })
    }
    if (isCityArea && isCityLetters) {
      dispatch({
        cityArea: localstorage.get("rrc_city_area"),
        cityLetters: localstorage.get("rrc_city_letters")
      })
    } else {
      getNavigationCityInfo({
        city: city.city,
        province: city.province_name,
        type: isCityLetters ? 3 : 0
      })
        .then((result) => {
          result.data.cityArea &&
            localstorage.set("rrc_city_area", result.data.cityArea)
          result.data.cityLetters &&
            localstorage.set("rrc_city_letters", result.data.cityLetters)
          dispatch({ ...result.data })
        })
        .catch(() => {
          // console.log(err)
        })
    }
  }
  useEffect(() => {
    if (props.modalVisible) {
      handleGetZhoubian()
    }
  }, [props.modalVisible])
  const letterSplitIndex = useMemo(() => {
    const mid = Math.ceil(localState?.cityLetters?.length / 2)
    return mid < 13 ? 13 : mid
  }, [localState?.cityLetters?.length])
  const letters = useMemo(() => {
    if (localState?.cityLetters && localState?.cityLetters instanceof Array ) {
      const tempArr = localState?.cityLetters?.reduce((p, c) => {
        p.push(c.name)
        return p
      }, [])
      return tempArr
    }
    return []
  }, [localState?.cityLetters?.length])

  useEffect(() => {
    // 问题列表点击
    const questions = document.querySelectorAll(".question")
    const answers = document.querySelectorAll(".answer")
    const questionClickHandler = (e) => {
      const visible = e.target.nextSibling.style.display
      Array.from(answers).map((answer) => {
        answer.style.display = "none"
        return {}
      })
      e.target.nextSibling.style.display =
        visible !== "block" ? "block" : "none"
      global.$rbl.push({
        event: "pc_offline_city_question"
      })
    }
    if (questionList.current) {
      Array.from(questions).map((question) => {
        question.addEventListener("click", questionClickHandler)
        return {}
      })
    }
    return () => {
      Array.from(questions).map((question) => {
        question.removeEventListener("click", questionClickHandler)
        return {}
      })
    }
  }, [questionList.current])

  // 添加异地购车城市
  const handleBuyCityClick = (param) => {
    // eslint-disable-line
    if (chooseCities.length === 3) {
      setMostThree(true)
      return
    }
    setMostThree(false)
    lastOne && setLastOne(false)
    for (let index = 0; index < chooseCities.length; index++) {
      const element = chooseCities[index]
      if (element.id === param.id) {
        return
      }
    }
    setChooseCities(() => {
      chooseCities.push(param)
      return [...chooseCities]
    })
  }
  // 删除异地购车城市
  const handleBuyCityDel = (param) => {
    let paramIndex = null
    for (let index = 0; index < chooseCities.length; index++) {
      const element = chooseCities[index]
      if (element.id === param.id) {
        paramIndex = index
        break
      }
    }
    setChooseCities(() => {
      chooseCities.splice(paramIndex, 1)
      return [...chooseCities]
    })
  }

  // 城市选择的点击事件
  const handleChooseCity = async (c) => {
    // 详情页跳转至列表页
    if (base.page_name === "car-detail") {
      // window.location.href = `https://www.renrenche.com/buy`
      router.push({
        pathname: "/buy"
      })
    }
    if (city?.city !== c.abbr) {
      try {
        const result = await getPublicVariables({ city: c.abbr })
        // 保存city信息
        usedispatch({
          type: "SET_CITY_INFO",
          data: result.data.data.city
        })
        // 修改rrc_record_city
        $cookie.remove("rrc_session_city")
        $cookie.set("rrc_record_city", c.abbr, { time: 60 * 60 * 24 })
        $cookie.set("rrc_modified_city", true)
      } catch (error) {
        // console.log(error)
      }
    }
    setLocalcitychooseShow(false)
    global.$rbl.push({
      event: "pc_offline_city_question"
    })
  }

  // 开始选车
  const handleToBuy = () => {
    if (!chooseCities.length) {
      setLastOne(true)
      return
    }
    setLastOne(false)
    let multCity = ""
    chooseCities.map((options) => {
      multCity += `${options.abbr}_`
      return {}
    })
    $cookie.remove("rrc_muti_city_name")
    $cookie.set("rrc_muti_city_name", multCity, { time: 31536000, path: "/" })

    // 点击埋点
    global.$rbl.push({
      event: "pc_offline_city_search"
    })
    if (!$cookie.get("search_new_visitor")) {
      global.$rbl.push({
        event: "pc_offline_user_visit"
      })
      $cookie.set("search_new_visitor", 1)
    }
    // TODO:router.push到本页面只走服务端渲染？
    // router.push({ pathname: "/buy" })
    window.location.href = "/buy"
    setLocalcitychooseShow(false)
    props.onModalClose && props.onModalClose()
  }

  return (
    <>
      <Modal
        centered
        footer={null}
        width={792}
        style={{ top: 0, padding: "0px", width: '792px' }}
        visible={Boolean(props.modalVisible)}
        onCancel={props.onModalClose}
        closable={false}
        bodyStyle={{ padding: "0px" }}
      >
        <div className="r-inocity">
          <div className="r-inocity-head clearfix">
            <div
              className="close-con"
              onClick={() => {
                setLocalcitychooseShow(false)
                props.onModalClose && props.onModalClose()
              }}
            >
              <i className="iconfont icon-close" />
            </div>
          </div>
          <div className="r-inocity-city clearfix">
            <div className="ip-address">
              <div className="title">
                您IP的定位是：
                {/* <span className="img-position" /> */}
                <i className="iconfont iconhuaban1 img-position" />
                <span className="nc">{city.city_name}</span>
                <a
                  className="position-problem"
                  onClick={() => {
                    setLocalcitychooseShow(!localcitychooseShow)
                  }}
                >
                  （点击切换城市）
                </a>
                <span className="not-notice">
                  该城市暂无车源，您可在其他城市选购车辆。
                </span>
              </div>
              {localcitychooseShow && (
                <div className="ip-address__city-select">
                  <div className="city-select__change">
                    <ul className="city-select__change-city-list">
                      {letters.map(l => (
                        <li key={l}>
                          <a href={`#anchor-${l}`}>{l}</a>
                        </li>
                      ))}
                    </ul>
                    <div
                      className="close-btn"
                      onClick={() => {
                        setLocalcitychooseShow(false)
                      }}
                    >
                      关闭
                    </div>
                  </div>
                  <div className="change-city clearfix">
                    <div className="change-city__box">
                      <div className="letter clearfix">
                        <div className="letter-column">
                          {localState.cityLetters?.length &&
                            localState.cityLetters
                              .slice(0, letterSplitIndex)
                              .map(v => (
                                <div
                                  className="box-item"
                                  key={v.name}
                                  id={`anchor-${v.name}`}
                                >
                                  <div className="title">{v.name}</div>
                                  <div className="cities">
                                    {v.city_list.map(c => (
                                      <span key={c.id}>
                                        <a
                                          className={
                                            city?.city === c.city
                                              ? "citySelect"
                                              : ""
                                          }
                                          onClick={e => handleChooseCity(c, e)
                                          }
                                        >
                                          {c.city}
                                        </a>
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              ))}
                        </div>
                        <div className="letter-column">
                          {localState.cityLetters?.length &&
                            localState.cityLetters
                              .slice(letterSplitIndex)
                              .map(v => (
                                <div
                                  className="box-item"
                                  key={v.name}
                                  id={`anchor-${v.name}`}
                                >
                                  <div className="title">{v.name}</div>
                                  <div className="cities">
                                    {v.city_list.map(c => (
                                      <span key={c.id}>
                                        <a
                                          key={c.id}
                                          className={
                                            city?.city === c.city
                                              ? "citySelect"
                                              : ""
                                          }
                                          onClick={e => handleChooseCity(c, e)
                                          }
                                        >
                                          {c.city}
                                        </a>
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="introduce-cities-result">
              <div className="cities-result-left">
                <span>选择购车城市：</span>
              </div>
              <div
                className="cities-result-right"
                onClick={() => {
                  handleToBuy()
                }}
              >
                开始选车
              </div>
              <div className="cities-result-center">
                {chooseCities.map(item => (
                  <span className="choose-city" key={item.city}>
                    {item.city}
                    <div
                      key={item.city}
                      className="result-close"
                      onClick={() => {
                        handleBuyCityDel(item)
                      }}
                    />
                  </span>
                ))}
                <div className="city-choose-box">
                  <div className="city-choose">
                    <span onClick={() => setCitychooseShow(!citychooseShow)}>
                      城市选择
                    </span>
                    <div
                      className="div_city_img"
                      onClick={() => setCitychooseShow(!citychooseShow)}
                    />
                    {citychooseShow && (
                      <div className="area-city">
                        <div
                          className="close-area-city"
                          onClick={() => setCitychooseShow(false)}
                        />
                        {localState?.hot?.length > 0 && (
                          <div className="area-line">
                            <span className="area">推荐</span>
                            {localState?.hot.map(item => (
                              <a
                                key={item.id}
                                className={[
                                  "city-select",
                                  "province-item",
                                  chooseCities.filter(e => e.id === item.id)
                                    .length
                                    ? "active"
                                    : null
                                ].join(" ")}
                                onClick={() => {
                                  handleBuyCityClick(item)
                                }}
                              >
                                {item.city}
                              </a>
                            ))}
                          </div>
                        )}

                        {localState?.cityArea
                          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                          .map(areaItem => (
                            <div className="area-line" key={areaItem.name}>
                              <span className="area" key={areaItem.name}>
                                {areaItem.name}
                              </span>
                              {areaItem.city_list.map(item => (
                                <a
                                  key={item.id}
                                  className={[
                                    "city-select",
                                    "province-item",
                                    chooseCities.filter(e => e.id === item.id)
                                      .length
                                      ? "active"
                                      : null
                                  ].join(" ")}
                                  onClick={() => {
                                    handleBuyCityClick(item)
                                  }}
                                >
                                  {item.city}
                                </a>
                              ))}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
                {lastOne && (
                  <span className="requirement">最少选择1个城市</span>
                )}
                {mostThree && (
                  <span className="requirement">最多选择3个城市</span>
                )}
              </div>
            </div>
            <p className="remind">
              注：所展现车辆均符合您所在城市的汽车迁入标准
            </p>
          </div>
          <div className="r-inocity-process" />
          <div className="r-inocity-question clearfix">
            <div className="picture-area">
              <div className="question-img" alt="常见问题" />
              <div className="question-text">常见问题</div>
            </div>
            <div className="content" ref={questionList}>
              <h5 className="question">1. 异地购车有什么限制和注意事项？</h5>
              <p className="answer">
                a.购买车辆需符合迁入地环保标准以及注册年限的规定 <br />
                b.异地购车过户分为迁出提档和迁入落户两部分，落户时需要购买方提供当地户口本、居住证、暂住证等有效证件
                <br />
                c.部分城市由于限购，需要买家提供当地购车指标 <br />
                d.由于很多城市提档时要求购买者本人亲自到场，人人车目前暂不支持买家不到场的异地购车
                <br />
                e.异地购车的过户费用分为提档和落户两部分，部分城市提档时会收取一定比例的税金
              </p>
              <h5 className="question">2. 异地购车需要准备哪些材料？</h5>
              <p className="answer">
                提档时，卖家会准备好车辆相关全部手续，包括登记证、行驶证、交强险、购置税证、新车发票、进关单等，买家准备好身份证即可；落户时，买家需要还需要提供迁入所在地的户口本、居住证、暂住证等相关证件。
              </p>
              <h5 className="question">
                3. 在人人车异地购车和本地购车流程有什么不同？
              </h5>
              <p className="answer">
                考虑到异地购车用户上门看车的成本，异地购车时，销售经理会尽可能通过电话帮助双方事先达成成交意向，并且建议买家支付一定的订金，锁定车辆，以免出现上门看车时已售出或其他意外情况。
              </p>
              <h5 className="question">
                4. 异地购车可否享受一年/两万公里的质保？
              </h5>
              <p className="answer">
                异地购买的车辆同样享受人人车一年/两万公里的质保，可到任意开通服务的分公司接受售后服务。
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default InoCity

InoCity.propTypes = {
  modalVisible: PropTypes.bool,
  onModalClose: PropTypes.func
}

InoCity.defaultProps = {
  modalVisible: false,
  onModalClose: () => ({})
}
