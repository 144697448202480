import React, { Fragment } from "react"
import App from "next/app"
import Head from "next/head"
import { Provider } from "react-redux"
import withRedux from "next-redux-wrapper"
import ErrorPage from "@/pages/_error/index.ssr"
import IEBrowser from "@/pages/other/ie/tips/index.ssr"
import nextServeMiddleware from "@/middleware"
import Layout from "@/layout/default"
import LayoutNav from "@/layout/layout-nav"
import "@/styles/base.scss"
import nextPlugins from "@/plugins"
import { forceCheck } from "react-lazyload"
import dynamic from "next/dynamic"
import {
  $gtIE,
  $ltIE,
  checkCommonApiSuccess,
  getPagename,
  localstorage,
  sessionstorage
} from "$utils"
import "@/utils/log"
import createStore from "$store"
import { getPublicVariables } from "./service"

const DynamicComponentWithNoSSR = dynamic(
  import("../components/rc-third-script"),
  {
    ssr: false
  }
)
class MyApp extends App {
  constructor() {
    super()
    // 监听ie浏览器变化
    this.unsubscribeIE = null
    this.state = {
      main: null,
      gtIE: false,
      ltIE: false,
      apiError: {},
      layout: true
    }
    this.getPerformanceTimer = null

    // 执行客户端plugin内 方法
    if (process.browser) {
      nextPlugins()
    }
  }

  // 初始化 监听store变化
  initCheckIE() {
    const { store } = this.props
    let ieinfo = store.getState().ie || {}
    const apierr = store.getState().apiError || {}
    const isLayout = store.getState().layout || "nav"
    const fn = () => {
      // 根据state.ie 判断是否触发ie tips弹层
      const ie = store.getState().ie || {}
      if (ieinfo?.time !== ie?.time || ie?.version <= 10) {
        ieinfo = ie
        if ($ltIE(ieinfo)) {
          this.setState({ ltIE: $ltIE(ieinfo) })
        } else if ($gtIE(ieinfo)) {
          this.setState({ gtIE: $gtIE(ieinfo) })
        } else {
          this.setState({ gtIE: false, ltIE: false })
        }
      }
      // 根据state.apiError 判断是否触发错误页面渲染弹层
      if (apierr.code > 0) {
        this.setState({ apiError: apierr })
      }

      // 根据state.layout 判断是否需要nav布局
      if (isLayout !== "nav") {
        this.setState({ layout: false })
      } else {
        this.setState({ layout: true })
      }
      return false
    }
    this.unsubscribeIE = store.subscribe(fn)
  }

  // render 初始化
  initRender() {
    const {
 Component, pageProps, store, router 
} = this.props
    const { gtIE, ltIE, apiError } = store.getState()
    let main = null
    if (gtIE) {
      main = <IEBrowser route={router} store={store} />
    } else if (apiError && apiError.code > 0) {
      /* 根据statuscode 加载错误页面 */
      main = (
        <ErrorPage statusCode={apiError.code} router={router} store={store} />
      )
      // TODO: 修改此处 layoutType 变更到store中
    } else if (store.getState().layout === "nav") {
      // eslint-disable-line
      main = (
        <LayoutNav store={store}>
          {ltIE ? <IEBrowser route={router} store={store} /> : ""}
          <Component {...pageProps} />
        </LayoutNav>
      )
    } else {
      main = (
        <Layout store={store}>
          {ltIE ? <IEBrowser route={router} store={store} /> : ""}
          <Component {...pageProps} />
        </Layout>
      )
    }
    return main
  }

  // 检测服务端前置请求是否成功
  checkSSRInit = async () => {
    await checkCommonApiSuccess({
      ...this.props,
      getPublicVariables
    })
  };

  componentDidMount() {
    forceCheck()
    window.store = this.props.store
    global.$rbl.push({
      event: "pageview",
      content: {
        page_name: getPagename(),
        new_page_name: window.location.pathname
      }
    })
    this.initCheckIE()
    setTimeout(() => {
      global.$rbl.collect()
    }, 1000)

    this.checkSSRInit()

    sessionstorage.set("pageLoadTime", new Date().getTime()) // 记录一个session内线索首次提交

    // window.onpopstate = (e) => {
    //   // console.log(e)
    //   // if (e?.state?.url?.includes("login")) {
    //   //   return false
    //   // }
    //   return true
    // }
  }

  componentWillUnmount() {
    // 清空localStorage过期的key
    localstorage.clearAllPass()
    // 清空setTime
    clearTimeout(this.getPerformanceTimer)
  }

  render() {
    require("es6-promise").polyfill()
    const { store, router } = this.props
    const main = this.initRender()

    return (
      <Fragment>
        <Head>
          <title>人人车</title>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta
            httpEquiv="mobile-agent"
            content="format=html5; url=https://m.renrenche.com"
          />
          <meta name="renderer" content="webkit" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta
            name="keywords"
            content="二手车,二手车市场,二手车交易,二手车交易市场"
          />
          <meta
            name="description"
            content="人人车二手车市场,二手车交易市场,为您提供二手车交易信息,二手车出手转让信息,免费提供二手车评估,二手车报价,详询4000350113！"
          />
          <link
            rel="shortcut icon"
            href="//img1.rrcimg.com/dist/pc/images/favicon-60ac45c9.ico"
            type="image/x-icon"
          />
          <link
            rel="bookmark"
            href="//img1.rrcimg.com/dist/pc/images/favicon-60ac45c9.ico"
            type="image/x-icon"
          />
          <link rel="dns-prefetch" href="//misc.rrcimg.com" />
          <link rel="dns-prefetch" href="//img1.rrcimg.com" />
          <link rel="dns-prefetch" href="//img2.rrcimg.com" />
          <link
            rel="stylesheet"
            href="https://misc.rrcimg.com/_next/static/font/iconfont.css?t=1672213438809"
          />
        </Head>
        <Provider store={store} route={router}>
          <DynamicComponentWithNoSSR />
          {main}
        </Provider>
      </Fragment>
    )
  }
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  // ctx.store.dispatch({ type: "SET_BASE_INFO", data: { show_header: true } })
  // 获取 Component Name
  const compName =
    typeof Component === "function"
      ? Component.name
      : typeof Component === "object"
      ? Component.pageName
      : ""
  // 判断middleware 执行条件
  if (!process.browser && compName !== "Error") {
    // next middleware 初始化
    await nextServeMiddleware(ctx)
  }
  let pageProps = {}
  if (Component.getInitialProps) {
    const initProps = await Component.getInitialProps({ ctx })
    pageProps = { ...initProps }
  }
  return { pageProps }
}

export default withRedux(createStore)(MyApp)
