// 市场投放携带fr及回传参数，存储投放链接至cookie,保存24小时，给后端做回传使用

import {
  getUrlSearch, base64Encrypt, $cookie
} from '$utils'

const initSpecialFr = () => {
  const frStr = getUrlSearch('fr')
  const expire = 86400 // 存储24小时60 * 60 * 24 封装的cookie 单位是s
  if (frStr) { // 新的渠道进来需要覆盖
    $cookie.set('rrc_logidurl', base64Encrypt(window.location.href), { time: expire, path: '/', domain: 'renrenche.com' })
  }
}
export default () => {
  initSpecialFr()
}
