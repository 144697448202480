/* eslint-disable no-undef */
global.syn = (...arg) => {
  if (process.env.LOG_ENV === 'syn') {
    console.log(arg)
  }
}
global.ytt = (...arg) => {
  if (process.env.LOG_ENV === 'ytt') {
    console.log(arg)
  }
}
global.zjj = (...arg) => {
  if (process.env.LOG_ENV === 'zjj') {
    console.log(arg)
  }
}
export {}
