import Ga from './core'
import Exposure from './core/exposure'
import EventGa from './core/event'

const rbl = new Ga()

export const collect = () => {
  if (rbl.exposureGaInstance) {
    rbl.exposureGaInstance._unCollect()
  }
  rbl.exposureGaInstance = new Exposure(rbl)
  if (rbl.eventGaInstance) {
    rbl.eventGaInstance._unCollect()
  }
  rbl.eventGaInstance = new EventGa(rbl)
}
// collect()
export default rbl
