// import ga from './index'
import 'intersection-observer'

function _merge(data = []) {
  if (!data.length) { return false }
  const dataArr = data[0]
  const keys = Object.keys(dataArr.content)
  if (keys.length !== 1 && process.env.NODE_ENV === 'development') {
    throw new Error('content内格式错误，必须有一个且只能有一个key')
  }

  const key = keys[0]
  dataArr.content[key] = data.reduce((pre, cur) => pre.concat(cur.content[key]), [])

  return dataArr
}
class Exposure {
  constructor(rbl) {
    this.$rbl = rbl
    this.dotDataArr = {}
    this.exposureList = []
    this._init()
    this._getEle()
  }

  _init() {
    this._observer = new IntersectionObserver(
      (entries) => {
        Array.from(entries).map((entrie) => {
          if (entrie.isIntersecting) {
            this._collect(entrie)
          }
          return null
        })
      },
      {
        root: null
      }
    )
    window.addEventListener('DOMNodeInserted', () => {
      // event.target就是依次插入的DOM节点
      this._getEle()
    }, false)
  }

  // eslint-disable-next-line class-methods-use-this
  _getEle() {
    // 收集报告dom
    this.exposureList = Array.from(document.querySelectorAll('[data-exposure]'))
    if (this.exposureList.length > 0) {
      this.exposureList.map((el) => {
        let attr = el.getAttribute('data-exposure') || '{}'
        try {
          attr = JSON.parse(attr)
        } catch (error) {
          // console.log('attr不是对象')
        }
        el.exposureData = attr
        this._observer && this._observer.observe(el)
        return null
      })
    }
  }

  _unCollect() {
    this.exposureList.map((el) => {
      this._observer.unobserve(el)
      return null
    })
    window.removeEventListener('DOMNodeInserted', () => {
      // event.target就是依次插入的DOM节点
      // console.log(event.target)
      this._getEle()
    })
  }

  // 收集埋点信息
  _collect(entrie) {
    let data = entrie.target.getAttribute('data-exposure') || '{}'
    try {
      data = JSON.parse(data)
    } catch (error) {
      // console.log('attr不是对象')
    }
    this._observer.unobserve(entrie.target)
    // this.$rbl.push(data)
    // 一条条发送
    if (!data.max || data.max <= 1) {
      delete data.max
      this.$rbl.push(data)
    } else {
      // 合并发送
      const { max } = data
      delete data.max
      if (!this.dotDataArr[data.event]) { this.dotDataArr[data.event] = [] }

      this.dotDataArr[data.event].push(data)
      const len = this.dotDataArr[data.event].length
      if (len >= max) {
        const sendData = _merge(this.dotDataArr[data.event].splice(0, max))
        this.$rbl.push(sendData)
      }

      this.idleSend()
    }
    // 曝光后移除曝光属性
    entrie.target.removeAttribute('data-exposure')
  }

  // 将要发送的数据合并处理

  // 当2秒内没有埋点数据进来，则将dotDataArr里面的剩余数据一起发送，
  idleSend() {
    clearTimeout(this.timer)

    const keys = Object.keys(this.dotDataArr)
    if (!keys.length) { return }

    this.timer = setTimeout(() => {
      for (let i = 0; i < keys.length; i++) {
        const arr = _merge(this.dotDataArr[keys[i]])
        arr && this.$rbl.push(arr)
      }
      this.dotDataArr = {}
    }, 2000)
  }
}


export default Exposure
