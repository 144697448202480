import { $cookie, getNewVisitorUUID, getRslUUID } from '$utils'

const checkcookie = () => {
  // rls_uuid
  if (!$cookie.get('rls_uuid')) {
    $cookie.set('rls_uuid', getRslUUID(), { time: 7 * 24 * 60 * 60 })
  }

  // rrc_ss
  if (!$cookie.get('rrc_ss')) {
    $cookie.set('rrc_ss', 'initiative')
  }

  // uuid
  if (!$cookie.get('new_visitor_uuid')) {
    $cookie.set('new_visitor_uuid', getNewVisitorUUID(), { time: 7 * 24 * 60 * 60 })
  }
}

export default async () => {
  checkcookie()
}
