// 配置文件 config
const config = (log4js, dirname) => ({
  // pm2: isProd,
  // pm2InstanceVar: 'isMaster',
  disableClustering: true,
  appenders: {
    logFile: {
      type: 'file',
      filename: `${dirname}/logFile`, // 你要写入日志文件的路径
      alwaysIncludePattern: true, // 将模式包含在当前日志文件的名称及其备份中
      pattern: 'yyyy-MM.log',
      encoding: 'utf-8',
      maxLogSize: '10M' // 文件最大存储空间
    },
    logErrorFile: {
      type: 'dateFile',
      filename: `${dirname}/logFile`, // 你要写入日志文件的路径
      alwaysIncludePattern: true, // 将模式包含在当前日志文件的名称及其备份中
      pattern: 'yyyy-MM.error.log', // 按月记录
      encoding: 'utf-8',
      maxLogSize: '10M' // 文件最大存储空间
    },
    serverSideApi: {
      type: 'dateFile',
      filename: `${dirname}/logFile`,
      alwaysIncludePattern: true,
      pattern: 'yyyy-MM-dd.apiTime.log', // 按天记录
      encoding: 'utf-8',
      maxLogSize: '20M' // 文件最大存储空间,pc详情页日访问量40000内，10000条记录大概2M,按访问一次产生2条记录
    },
    logConsole: {
      type: 'console'
    }
  },
  categories: {
    default: {
      appenders: ['logFile'],
      level: 'all'
    },
    info: {
      appenders: ['logFile'],
      level: 'all'
    },
    apiTime: {
      appenders: ['serverSideApi'],
      level: 'all'
    },
    error: {
      appenders: ['logErrorFile'],
      level: 'error'
    },
    console: {
      appenders: ['logConsole'],
      level: log4js.levels.ALL.levelStr
    }
  }
})

export default (ctx) => {
  if (!process.browser) {
    try {
      const path = require('path')
      const log4js = require('log4js')
      const log4jsconfig = config(log4js, `${path.resolve()}/log4`)
      log4js.configure(log4jsconfig)
      const isProd = process.env.NODE_ENV === 'production'
      const logObj = {
        info(msg, ...args) {
          const logger = log4js.getLogger(isProd ? 'info' : 'console')
          logger.info(msg, ...args)
        },
        debug(msg, ...args) {
          const logger = log4js.getLogger(isProd ? 'info' : 'console')
          logger.debug(msg, ...args)
        },
        api(msg, ...args) {
          const logger = log4js.getLogger(isProd ? 'apiTime' : 'apiTime')
          logger.info(msg, ...args)
        },
        error(msg, ...args) {
          const logger = log4js.getLogger(isProd ? 'error' : 'console')
          logger.error(msg, ...args)
        }
      }
      ctx.$logger = logObj
      global.$logger = logObj
    } catch (error) {
      console.log(error)
    }
  }
}
