import Router from "next/router"
import NProgress from "nprogress"
import { useSelector, useDispatch } from "react-redux"
import { forceCheck } from "react-lazyload"
import { getPagename, sessionstorage } from "$utils"

const loading = () => {
  const { base, layout } = useSelector((state) => state); // eslint-disable-line
  const dispatch = useDispatch()
  Router.onRouteChangeStart = (toPath) => {
    // eslint-disable-line
    NProgress.start()
    layout !== "nav" &&
      dispatch({
        type: "SET_LAYOUT",
        data: "nav"
      })
    const baseInfoObj = {}
    if (base.show_side_fixed !== true) {
      baseInfoObj["show_side_fixed"] = true
    }
    if (base.show_header !== true) {
      baseInfoObj["show_header"] = true
    }
    if (base.show_footer !== true) {
      baseInfoObj["show_footer"] = true
    }
    if (base.header_fixed !== true) {
      baseInfoObj["header_fixed"] = true
    }
    if (base.header_light !== false && toPath !== "/guarantee") {
      baseInfoObj["header_light"] = false
    }
    Object.keys(baseInfoObj).length > 0 &&
      dispatch({ type: "SET_BASE_INFO", data: { ...baseInfoObj } })
  }
  Router.onRouteChangeComplete = (toPath) => {
    NProgress.done()
    forceCheck()
    sessionstorage.set("pageLoadTime", new Date().getTime()) // 记录一个session内线索首次提交
    if (toPath.split("?")[0] !== Router.pathname) {
      setTimeout(() => {
        global.$rbl.push({
          event: "pageview",
          content: {
            page_name: getPagename(),
            new_page_name: window.location.pathname
          }
        })
        global.$rbl.collect()
      }, 1000)
    }
  }
  Router.onRouteChangeError = () => NProgress.done()
}
export default loading
