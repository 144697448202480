import React, {
  useEffect, useState, useMemo, useReducer
} from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { getPublicVariables, getNavigationCityInfo } from './service'
import { $hooks, $cookie, localstorage } from '$utils'
import './index.scss'

const CityChoose = ({ isShow }) => {
  const usedispatch = useDispatch()
  const city = useSelector(state => state.city)
  const base = useSelector(state => state.base)
  const [defaultShow, setDefaultShow] = useState(true) // 选择城市后隐藏城市选择视图
  const [isHovering, hoverProps] = $hooks.useHover({ mouseEnterDelayMS: 200, mouseLeaveDelayMS: 500 })
  const [visible, setVisible] = useState(false)
  const [localState, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    cityArea: [], cityLetters: [], hot: [], near: []
  })

  // 获取周边城市
  // type: 1 hot+near 2 cityLetters 3 cityArea
  const handleGetZhoubian = async () => {
    const isCityLetters = localstorage.get('rrc_city_letters')?.length > 0
    const isHotCity = localstorage.get(`rrc_hot_${city.city}`).length > 0
    const isNearCity = localstorage.get(`rrc_near_${city.city}`).length > 0
    const isAreaCity = localstorage.get('rrc_city_area').length > 0
    if (isCityLetters && !localState.cityLetters?.length) {
      dispatch({
        cityLetters: localstorage.get('rrc_city_letters')
      })
    }
    if (isHotCity && !localState.hot?.length) {
      dispatch({
        hot: localstorage.get(`rrc_hot_${city.city}`)
      })
    }
    if (isNearCity && !localState.near?.length) {
      dispatch({
        near: localstorage.get(`rrc_near_${city.city}`)
      })
    }
    if (isAreaCity && !localState.cityArea?.length) {
      dispatch({
        cityArea: localstorage.get('rrc_city_area')
      })
    }
    if (!isCityLetters || !isHotCity) {
      try {
        const result = await getNavigationCityInfo({
          city: city.city,
          province: city.province_name,
          type: isCityLetters ? 1 : 0
        })
        localstorage.set(`rrc_hot_${city.city}`, result?.data?.hot || {})
        localstorage.set(`rrc_near_${city.city}`, result?.data?.near || {})
        result.data?.cityLetters && localstorage.set('rrc_city_letters', result.data.cityLetters)
        dispatch({ ...result.data })
      } catch (error) {
        // console.log(error)
      }
    }
  }
  useEffect(() => {
    if ((isShow || isHovering) && defaultShow) {
      handleGetZhoubian()
      setTimeout(() => { setVisible(true) }, 160)
    } else {
      setVisible(false)
    }
  }, [isShow, isHovering, defaultShow])

  const letterSplitIndex = useMemo(() => {
    const mid = Math.ceil(localState?.cityLetters?.length / 2)
    return mid < 12 ? 12 : mid
  }, [localState?.cityLetters?.length])

  // 城市选择的点击事件
  const handleChooseCity = async (c) => {
    global.$rbl.push({
      event: `search_titlebar_${c.abbr}`,
      content: {
        expand: {
          tag_value: c.city
        }
      }
    })
    // 详情页跳转至列表页
    if (base.page_name === 'car-detail') {
      window.location.href = `https://www.renrenche.com/buy`
    }
    if (city.city !== c.abbr) {
      try {
        const result = await getPublicVariables({ city: c.abbr })
        // 保存city信息
        usedispatch({
          type: 'SET_CITY_INFO',
          data: result.data.data.city
        })
        // 修改rrc_record_city 时间设置为24小时，防止修改完城市再跳转老主站cookie销毁
        // 不同的域下不能同时修改完全，删除后重新添加（.www.renrenche.com,www.renrenche.com）
        $cookie.remove('rrc_session_city')
        $cookie.remove('rrc_record_city')
        $cookie.set('rrc_record_city', c.abbr, { time: 60 * 60 * 24 })
        $cookie.set('rrc_modified_city', true)
      } catch (error) {
        // console.log(error)
      }
    }
    setDefaultShow(false)
  }

  useEffect(() => { setDefaultShow(true) }, [isShow])

  return (
    <>
      <div className={`r-cities r-cities--${visible ? 'show' : 'hidden'}`}>
        <div className="r-cities__box" {...hoverProps}>
          <div className="r-cities__box-inner">
            {
            localState.near?.length > 0
            && (
              <div className="box-item">
                <div className="title zb">周边</div>
                <div className="cities">
                  {
                    localState.near.sort((a, b) => a.city.localeCompare(b?.city)).map(c => (
                      <span key={c.id}>
                        <a className={city.city === c.city ? 'citySelect' : ''} onClick={e => handleChooseCity(c, e)}> {c.city}</a>
                      </span>
                    ))
                  }
                </div>
              </div>
            )
          }

            {
            localState.hot.length > 0
            && (
              <div className="box-item">
                <div className="title zb">热门</div>
                <div className="cities">
                  {localState.hot.sort((a, b) => a.city.localeCompare(b?.city)).map(c => <span key={c.id}><a className={city.city === c.city ? 'citySelect' : ''} onClick={e => handleChooseCity(c, e)}>{c.city}</a></span>)}
                </div>
              </div>
            )
          }

            <div className="letter clearfix">
              <div className="letter-column">
                {
                localState?.cityLetters?.length > 0 && localState.cityLetters.slice(0, letterSplitIndex).map(v => (
                  <div className="box-item" key={v.name}>
                    <div className="title">{v.name}</div>
                    <div className="cities">
                      {v.city_list.map(c => <span key={c.id}><a className={city.city === c.city ? 'citySelect' : ''} onClick={e => handleChooseCity(c, e)}>{c.city}</a></span>)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="letter-column">
                {
                localState.cityLetters?.length > 0 && localState.cityLetters.slice(letterSplitIndex).map(v => (
                  <div className="box-item" key={v.name}>
                    <div className="title">{v.name}</div>
                    <div className="cities">
                      {v.city_list.map(c => <span key={c.id}><a className={city.city === c.city ? 'citySelect' : ''} onClick={e => handleChooseCity(c, e)}>{c.city}</a></span>)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CityChoose

CityChoose.propTypes = {
  isShow: PropTypes.bool
}

CityChoose.defaultProps = {
  isShow: false
}
