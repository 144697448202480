import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Header from '@/components/rc-header'
import Footer from '@/components/rc-footer'
import SideShare from '@/components/rc-side-share'
import NoSSR from '@/components/rc-no-ssr'
import loading from './loading'


const Layout = ({ children }) => {
  const { base } = useSelector(state => state)
  loading()
  return (
    <>
      {base?.show_header && <Header />}
      <div className="r-content">
        {children}
      </div>
      <NoSSR>{base?.show_footer && <Footer />}</NoSSR>
      <NoSSR>{base?.show_side_fixed && <SideShare />}</NoSSR>
    </>
  )
}
export default Layout

Layout.propTypes = {
  children: PropTypes.any
}

Layout.defaultProps = {
  children: null
}
