import { post, get } from '$fetch'

/**
 * @desc 登录确认
 * @returns
 */
export const loginconfirm = data => post('/lurker/artemis/auth/authorize', { ...data, os: 'pc' }, { isFormData: false, isAutoMsg: false })

/**
 * @desc 退出登录
 * @returns
 */
export const logout = data => post('/lurker/artemis/auth/logout', { ...data, os: 'pc' }, { isFormData: false, isAutoMsg: false })

/**
 * @desc 400电话
 * @returns
 */
export const getphonesource = data => get('/lurker/api/user/phone_source?d=api&c=phone_source&m=phone', data, {
  isFormData: false,
  isAutoMsg: false,
  isLoading: false
})
