// 公共参数获取
import { getPublicVariables } from './service'
import { $cookie } from '$utils'

export default async (ctx) => {
  try {
    const _cookie = ctx.req.headers.cookie
    const sessionCity = $cookie.get('rrc_session_city', _cookie)
    const recordCity = $cookie.get('rrc_record_city', _cookie)
    let city =  sessionCity || recordCity
    const { fr } = ctx.query
    const result = await getPublicVariables({ fr, city })

    // city为空时  使用ip定位城市
    city = city || result.data.data.city.city
    const cookies = [
      ...result.headers['set-cookie'].filter(item => item.indexOf('rrc_record_city') < 0),
      `rrc_record_city=${city};path=/`,
      'server-common-request=1'
    ]

    ctx.res.setHeader('set-cookie', cookies)

    // 保存city信息
    ctx.store.dispatch({
      type: 'SET_CITY_INFO',
      data: result.data.data.city
    })
  } catch (error) {
    console.log('rc-common---Error', error?.status, error?.statusText)
    if (!process.browser) {
      ctx.store.dispatch({
        type: 'SET_ERRCODE',
        data: {
          code: error?.status || 500
        }
      })
    }
  }
}
