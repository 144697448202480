/*
 * @Author: yeungtg
 * @Date: 2019-12-10 14:59:11
 * @Last Modified by: sunyanan
 * @Last Modified time: 2022-06-30 14:19:27
 */
import { sessionstorage, $cookie } from '$utils'

const isObject = (value) => {
  const type = typeof value
  return value !== null && (type === 'object' || type === 'function')
}

/**
 * @desc 合并json
 * @param {*} source
 * @param {*} other
 */
export const merge = (source, other) => {
  if (!isObject(source) || !isObject(other)) {
    return other === undefined ? source : other
  }
  // 合并两个对象的 key，另外要区分数组的初始值为 []
  return Object.keys({
    ...source,
    ...other
  }).reduce(
    (acc, key) => {
      // 递归合并 value
      acc[key] = merge(source[key], other[key])
      return acc
    },
    Array.isArray(source) ? [] : {}
  )
}

/**
 * @desc cookie => json
 * @params [cookie]
 * @return {Function}
 */
export const getCookie = (document) => {
  const result = {}
  const _cookie = `${document.cookie};`
  const cookis = _cookie.split(';')
  for (let i = 0; i < cookis.length - 1; i++) {
    if (_cookie[i].split('=').length > 0) {
      // eslint-disable-next-line prefer-destructuring
      result[cookis[i].split('=')[0].trim()] = cookis[i].split('=')[1]
    }
  }
  return result
}

/**
 * @desc 详情页面扩展数据 global_event_properties:"car_id=590a0a39f0d8cad8"
 * @params web: RRC-Q0CC1-00001
 * @return {Function}
 */
export const detailCarId = () => (sessionstorage.get('car_id') ? `car_id=${sessionstorage.get('car_id')}` : null)

/**
 * @desc 数据描述
 * @params web: RRC-Q0CC1-00001
 * @return {Function}
 */
export const logDataJob = () => 'RRC-Q0CC1-00001'


/**
 * @desc 版本号
 * @return {String}
 */
export const logV = '2.0'

/**
 * @desc 当前页面标示符
 * @return {Function}
 */
export const logU = location => location.pathname

/**
 * @desc 页面title
 * @return {String}
 */
export const logDt = '人人车'

/**
 * @desc 人人车统计标示ID
 * @return {Function}
 */
export const logAc = () => 'RRC-QYH11-0001' // PC端标记


/**
 * @desc session, app端设置类似的识别码 sid == base64(uuid+timestamp)
 * @retrun {Function}
 */
export const logSid = () => {
  /* eslint-disable */
  const _base64Encode = (e) =>{
    var t, n, o, r, a, i, u = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
    for (o = e.length, n = 0, t = ''; o > n;) {
      if (r = 255 & e.charCodeAt(n++), n == o) {
        t += u.charAt(r >> 2), t += u.charAt((3 & r) << 4), t += '=='
        break
      }
      if (a = e.charCodeAt(n++), n == o) {
        t += u.charAt(r >> 2), t += u.charAt((3 & r) << 4 | (240 & a) >> 4), t += u.charAt((15 & a) << 2),
            t += '='
        break
      }
      i = e.charCodeAt(n++), t += u.charAt(r >> 2), t += u.charAt((3 & r) << 4 | (240 & a) >> 4),
        t += u.charAt((15 & a) << 2 | (192 & i) >> 6), t += u.charAt(63 & i)
    }
    return t
  }
  /* eslint-enable */
  const uuid = ''
  return _base64Encode(`${uuid}_${Date.now()}`)
}

/**
 * @desc uuid 前端生成， 格式 8-4-4-4-12，生成之后会在cookie中存7天
 * @retrun {String}
 */
export const logUuid = () => $cookie.get('new_visitor_uuid') || ''

/**
 * @desc 用户唯一标识ID
 * @return {String}
 */
export const logUserid = () => $cookie.get('rrc_userid') || ''

/**
 * @desc 城市字段 必须英文缩写
 * @return {Function}
 */
export const logCity = () => {
  const getCity = () => {
    let city = ''
    let regStr = /\/\w+/.exec(/com\/\w+/.exec(window.location.href))
    regStr = regStr && regStr[0]
    if (regStr === null || regStr === '/p') {
      city = 'cn'
    } else {
      city = regStr.substring(1)
    }
    return city
  }
  return $cookie.get('rrc_record_city') || getCity()
}

/**
 * @desc fr
 */
export const logFr = () => $cookie.get('rrc_fr') || 'bd_other'

/**
 * @desc ep 事件标识符
 * @return {Json}
 */
export const logEp = {}

/**
 * @desc ep.abtest 获得AB实验分组
 * @returns {Function}
 */
export const epGetABTest = () => {
  let val = $cookie.get('sigma-experiment') || '{}'
  try {
    val = JSON.parse(val)
  } catch (e) {
    val = {}
  }
  return val
}

/**
 * @desc 获得 ep.ss
 * @returns {String}
 */
export const epGetSS = () => {
  const ss = $cookie.get('rrc_ss')
  return ss || 'initiative'
}

/**
 * @desc ep.content.cityName 城市名称
 * @return {String}
 */
export const epCityName = () => decodeURI($cookie.get('current_cityName')) || ''

/**
 * @desc ep.content.channel 访问渠道
 * @return {String}
 */
export const epChannel = () => 'pc'

/**
 * @desc ep.content.browserInfo 浏览器信息
 * @return {String}
 */
export const browserInfo = () => ({
  isIE: /msie (\d+\.\d+)/i.test(navigator.userAgent),
  isWechat: /MicroMessenger/i.test(navigator.userAgent),
  cookieEnabled: navigator.cookieEnabled,
  localStorageEnabled: localStorage !== undefined,
  javaEnabled: navigator.javaEnabled(),
  trident: navigator.userAgent.includes('Trident'), // IE内核
  presto: navigator.userAgent.includes('Presto'), // opera内核
  webKit: navigator.userAgent.includes('AppleWebKit'), // 苹果、谷歌内核
  gecko:
      navigator.userAgent.includes('Gecko')
      && !navigator.userAgent.includes('KHTML'), // 火狐内核
  mobile: !!navigator.userAgent.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
  ios: !!navigator.userAgent.match(
    /\(i[^;]+;( navigator.userAgent;)? CPU.+Mac OS X/
  ), // ios终端
  android:
      navigator.userAgent.includes('Android')
      || navigator.userAgent.includes('Adr'), // android终端
  iPhone: navigator.userAgent.includes('iPhone'), // 是否为iPhone或者QQHD浏览器
  iPad: navigator.userAgent.includes('iPad'), // 是否iPad
  weixin: navigator.userAgent.includes('MicroMessenger'), // 是否微信 （2015-01-22新增）
  qq: navigator.userAgent.match(/\sQQ/i) === 'qq', // 是否QQ
  language:
      navigator.language
      || navigator.browserLanguage
      || navigator.systemLanguage
      || navigator.userLanguage
      || '',
  colorDepth: window.screen.colorDepth || 0,
  screen: `${window.screen.width || 0}x${window.screen.height || 0}`,
  userAgent: navigator.userAgent,
  title: document.title
})
