import {
  createStore,
  applyMiddleware
} from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import {
  composeWithDevTools
} from 'redux-devtools-extension/developmentOnly'
import reducer from './reducers'

const defaultState = {
  base: {
    show_header: true,
    show_footer: true,
    show_side_fixed: true,
    page_name: '',
    header_fixed: true,
    header_light: false
  },
  layout: 'nav',
  user: {
    phone: '',
    token: '',
    ucid: '',
    userid: ''
  },
  city: {
    city: 'cn',
    city_name: '全国',
    province_name: '全国',
    ip_province: '全国',
    is_online: 1,
    is_baomai_city: 1,
    fr: 'bd_other',
    ino: 1,
    jwtsignature: '',
    phone_source: {}
  }
}
const isDev = process.env.NODE_ENV === 'development' && process.browser

const middleware = isDev ? applyMiddleware(thunk, logger) : applyMiddleware(thunk)
const Store = (initialState = defaultState) => createStore(reducer, initialState, composeWithDevTools(middleware))
export default Store
