export default (ctx) => {
  let deviceAgent = ''
  if (!process.browser) {
    deviceAgent = ctx?.req?.headers['user-agent'] || ''
  } else {
    deviceAgent = window.navigator.userAgent
  }
  deviceAgent = deviceAgent.toLowerCase()

  // const isApp = deviceAgent.includes('renrenche')
  const isM = /Android|webOS|iPhone|iPod|BlackBerry/i.test(deviceAgent)
  // const isToutiao = /ToutiaoMicroApp/i.test(deviceAgent)
  // 如果访问为移动端，则跳到m首页

  if (process.env.NODE_ENV === 'production' && isM) {
      if (!process.browser) {
        ctx.res.redirect('https://m.renrenche.com')
      } else {
        window.location.href = 'https://m.renrenche.com'
      }
    }
}
