export default class EventGa {
  constructor(rbl) {
    this.$rbl = rbl
    this.collecList = []
    this.eventList = ['click', 'hover']
    this.eventList.forEach((event) => {
      this._getEle(event)
    })
  }

  _getEle(event) {
    // 收集报告dom
    const clickList = document.querySelectorAll(`[data-${event}]`)
    if (clickList.length > 0) {
      Array.from(clickList).map((el) => {
        this.collecList.push(el)
        this._collect(el, event)
        return null
      })
    }
  }

  _collect(el, event) {
    const handler = () => {
      let attr = el.getAttribute(`data-${event}`) || '{}'
      try {
        attr = JSON.parse(attr)
      } catch (error) {
        // console.log('attr不是对象')
      }
      this.$rbl.push(attr)
    }
    el.handler = handler
    el.event = event
    el && el.addEventListener(event === 'hover' ? 'mouseover' : event, el.handler)
  }

  _unCollect() {
    this.collecList.forEach((el) => {
      el && el.removeEventListener(el.event === 'hover' ? 'mouseover' : el.event, el.handler)
    })
  }
}
