import { combineReducers } from 'redux'

const reducers = {}
const files = require.context('./', true, /\w+\/index.(js|ts)$/)

files.keys().map((key) => {
  const KEY = key.match(/(\w+)\/index.(js|ts)$/)[1]
  const reducer = {
    [KEY]: files(key).default
  }
  return Object.assign(reducers, reducer)
})

const rootReducer = combineReducers(reducers)

export default rootReducer
