const layout = (state = null, action) => {
  switch (action.type) {
    case 'SET_LAYOUT': {
      return action.data
    }
    default:
      return state
  }
}
export default layout
