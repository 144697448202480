import * as Sentry from "@sentry/react"

export default async () => {
  if (process.env.SENTRY_ENV !== "development") {
    Sentry.init({
      dsn: "https://ce9bc569f0e84151874abebe999c823b@sentry.58.com/146",
      tracesSampleRate: 1.0,
      environment: process.env.SENTRY_ENV,
      release: window.__NEXT_DATA__.buildId
    })
    global.$sentry = Sentry
    global.$sentryLog = ({ title = "错误", info = "" }) => {
      if (global.$sentry) {
        global.$sentry.setExtra("详情", { info, time: Date() })
        global.$sentry.captureMessage(title)
      }
    }
  }
}
