import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import loading from './loading'

const Layout = ({ children }) => {
  loading()

  return (
    <Fragment>
      <div className="r-content">
        {children}
      </div>
    </Fragment>
  )
}
export default Layout

Layout.propTypes = {
  children: PropTypes.any
}

Layout.defaultProps = {
  children: null
}
