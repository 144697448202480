const base = (state = null, action) => {
  switch (action.type) {
    case 'SET_SEARCH_INFO': {
      return {
        ...state,
        ...(action.data)
      }
    }
    default:
      return state
  }
}
export default base
