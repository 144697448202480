import getConfig from 'next/config'

const middle = require.context('./', true, /\.\/rc-\w+\.js$/)
const { publicRuntimeConfig } = getConfig()
const middleConfig = publicRuntimeConfig.middleware
export default async (ctx) => {
  const middleList = {}
  middle.keys()?.map((key) => {
    const KEY = key.match(/.\/(rc-\w+).(js|ts)$/)[1]
    const item = {
      [KEY]: middle(key).default
    }
    return Object.assign(middleList, item)
  })
  if (!process.browser) {
    await Promise.all(middleConfig?.map(item => middleList[item](ctx)))
  } else { // if (process.env.BUILD_ENV === 'static') {
    await Promise.all(middleConfig?.map(item => middleList[item](ctx)))
  }
}
