const error = (state = null, action) => {
  switch (action.type) {
    case 'GET_IE': {
      return { }
    }
    case 'DEL_ERRCODE':
      return { ...state, ...(action.data) }

    case 'SET_ERRCODE':
      // if (!process.browser) {
      return { ...state, ...(action.data) }
      // }
      // return state

    case 'SET_ERRCODE2':
      return { ...state, ...(action.data) }

    default:
      return state
  }
}
export default error
